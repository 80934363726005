import { captureRemixErrorBoundaryError } from "@sentry/remix";
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useRouteError
} from "@remix-run/react";
import type {LinksFunction, LoaderFunctionArgs} from "@remix-run/node";
import stylesheet from "~/tailwind.css?url";
import {GeneralErrorBoundary} from "~/component/error-boundary";
import {ReactNode, useEffect} from "react";
import {isLoggedInRequestContext} from "~/helper/requestcontext.helper";
import {getRequestContext} from "~/service/session.server";
import {buildBrowserEnv} from "~/service/env.server";
import {initAnalyticsBrowser, registerUserAnalytics} from "~/helper/analytics.browser";
import DevToolbar from "~/component/dev/DevToolbar";
import {LoggedInProDto} from "~/dto/pro.dto";
import uniq from "lodash/uniq";

export const links: LinksFunction = () => [
  { rel: "stylesheet", href: stylesheet },
];

export type RootLoaderData = Awaited<ReturnType<typeof loader>>;

function getSingleGroupId(loggedInPro: LoggedInProDto | undefined) {
  if (!loggedInPro) {
    return null;
  }
  const groupIds = loggedInPro.permissions.map(perm => perm.placeGroupIds).flat();

  const uniqIds = uniq(groupIds);
  return uniqIds.length === 1 ? uniqIds[0] : null;
}

export async function loader({ request }: LoaderFunctionArgs) {
  const requestContext = await getRequestContext(request);

  const loggedInPro = isLoggedInRequestContext(requestContext)
    ? requestContext.loggedInPro
    : undefined;

  const singleGroupId = getSingleGroupId(loggedInPro);
  return {
    loggedInPro,
    loggedInProHasSingleGroup: singleGroupId !== null,
    browserEnv: buildBrowserEnv(),
  }
}

export function Layout({ children }: { children: ReactNode }) {

  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body>
        {children}
        <ScrollRestoration />
        <Scripts />

        {process.env.NODE_ENV === "development" && <DevToolbar />}
      </body>
    </html>
  );
}

export const ErrorBoundary = () => {
  const error = useRouteError();
  // TODO: check if sentry is enable
  captureRemixErrorBoundaryError(error);
  return <GeneralErrorBoundary />
};

export default function App() {
  const { browserEnv, loggedInPro } = useLoaderData<typeof loader>();

  useEffect(() => {
    initAnalyticsBrowser(browserEnv.AMPLITUDE_BROWSER_API_KEY);
    if (loggedInPro) {
      registerUserAnalytics(loggedInPro.id);
    }
  }, []);

  return (
    <>
      <Outlet />

      {/* Make env data available on window directly */}
      <script
        dangerouslySetInnerHTML={{
          __html: `window.browserEnv = ${JSON.stringify(browserEnv)}`,
        }}
      />
    </>
  )
}